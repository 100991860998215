import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter as useNextRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import i18nConfig from '@Root/i18nConfig';
import useUnsavedChangesStore from '@Store/useUnsavedChangesStore';
const useRouter = () => {
  const {
    i18n
  } = useTranslation();
  const currentLocale = i18n.language;
  const router = useNextRouter();
  const {
    isNavigationBlocked,
    setPendingRoute
  } = useUnsavedChangesStore(useShallow(state => ({
    isNavigationBlocked: state.isNavigationBlocked,
    setPendingRoute: state.setPendingRoute
  })));
  const push = (href: string, options?: NavigateOptions, ignoreBlockedNavigation: boolean = false) => {
    let hrefWithLocal = href;
    if (currentLocale !== i18nConfig.defaultLocale) {
      hrefWithLocal = `/${currentLocale}${href}`;
    }

    // cancel navigation if e.g. form is dirty
    // todo: could be removed if next offers a better solution, see: https://github.com/vercel/next.js/discussions/41934#discussioncomment-12077414
    if (isNavigationBlocked && !ignoreBlockedNavigation) {
      setPendingRoute(hrefWithLocal);
      return;
    }
    router.push(hrefWithLocal, options);
  };
  const pushShallow = (href: string) => {
    if (currentLocale === i18nConfig.defaultLocale) {
      window.history.pushState(null, '', href);
    } else {
      window.history.pushState(null, '', `/${currentLocale}${href}`);
    }
  };
  const replaceShallow = (href: string) => {
    if (currentLocale === i18nConfig.defaultLocale) {
      window.history.replaceState(null, '', href);
    } else {
      window.history.replaceState(null, '', `/${currentLocale}${href}`);
    }
  };
  return {
    // todo: refactor remaining router function if necessary
    ...router,
    push,
    pushShallow,
    replaceShallow
  };
};
export default useRouter;