'use client';

import { createContext, useContext } from 'react';

import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';
import ThemeModel from '@Api/models/ThemeModel';
import UserModel from '@Api/models/UserModel';

import { UserImageSrcModel } from '@Components/atoms/UserImage/UserImage';

export type GlobalContent = {
  isGlobalContextInitialized: boolean;
  authorizationHeader: string | null;
  refreshToken: string | null;
  refreshTokenExpiration: number | null;
  loggedIn: boolean | null;
  login: (authorizationHeader: string, refreshToken?: string, refreshTokenExpiration?: number) => void;
  logout: () => void;
  user: LocalStorageUserModel | null;
  updateUser: (userModel: UserModel) => void;
  updateUsername: (username: string) => void;
  updateGravatar: (gravatar: UserImageSrcModel) => void;
  reloadUser: () => void;
  themes: ThemeModel[];
  fetchThemes: () => Promise<void>;
  currentTheme: ThemeModel | undefined;
  changeCurrentTheme: (theme: ThemeModel) => void;
};

export const MyGlobalContext = createContext<GlobalContent>({
  isGlobalContextInitialized: false,
  authorizationHeader: '',
  refreshToken: null,
  refreshTokenExpiration: null,
  loggedIn: null,
  login: (authorizationHeader: string, refreshToken?: string, refreshTokenExpiration?: number) => {
    return;
  },
  logout: () => {
    return;
  },
  user: null,
  updateUser: (userModel: UserModel) => {
    return;
  },
  updateUsername: (username: string) => {
    return;
  },
  updateGravatar: (gravatar: UserImageSrcModel) => {
    return;
  },
  reloadUser: () => {
    return;
  },
  currentTheme: undefined,
  themes: [],
  fetchThemes: async () => {
    return;
  },
  changeCurrentTheme: (theme: ThemeModel) => {
    return;
  },
});

export const useGlobalContext = () => useContext(MyGlobalContext);
